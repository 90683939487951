import { useState, useEffect } from "react";
import breakpoints from "../components/breakpoints";

export function useMediaQuery(query) {
    const [matches, setMatches] = useState(false);

    console.log(query)

    useEffect(() => {
        const media = window.matchMedia(query);
        if (media.matches !== matches) {
            setMatches(media.matches);
        }
        const listener = () => {
            setMatches(media.matches);
        };
        media.addEventListener('change', listener);
        return () => media.removeEventListener('change', listener);
    }, [matches, query]);

    return matches;
}

export const useMobileSSize = () => useMediaQuery(`(min-width: ${breakpoints.mobileS})`);
export const useMobileMSize = () => useMediaQuery(`(min-width: ${breakpoints.mobileM})`);
export const useMobileLSize = () => useMediaQuery(`(min-width: ${breakpoints.mobileL})`);
export const useTabletSize = () => useMediaQuery(`(min-width: ${breakpoints.tablet})`);
export const useLaptopSize = () => useMediaQuery(`(min-width: ${breakpoints.laptop})`);
export const useLaptopLSize = () => useMediaQuery(`(min-width: ${breakpoints.laptopL})`);
export const useDesktopHDSize = () => useMediaQuery(`(min-width: ${breakpoints.desktopHD})`);
export const useDesktopQHDSize = () => useMediaQuery(`(min-width: ${breakpoints.desktopQHD})`);
export const useDesktopUHDSize = () => useMediaQuery(`(min-width: ${breakpoints.desktopUHD})`);