import * as React from "react";
import { useRef } from "react";
import styled from "styled-components";
import { motion, useScroll, useTransform, useInView } from "framer-motion";
import Layout from "../components/layout";
import { Trans, useTranslation } from "gatsby-plugin-react-i18next";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Slider } from "../components/slider";
import { ImVimeo2, ImYoutube, ImInstagram } from "react-icons/im";
import { MdMailOutline, MdOutlinePhoneIphone } from "react-icons/md";
import { IconContext } from "react-icons";
import { SeoComponent } from "../components/seo";
import Obfuscate from "react-obfuscate";
import Forest1 from "../../assets/forest1.svg";
import Forest2 from "../../assets/forest2.svg";
import Forest3 from "../../assets/forest3.svg";
import Forest4 from "../../assets/forest4.svg";
import Forest5 from "../../assets/forest5.svg";
import TeamBG from "../../assets/team.svg";
import breakpoints from "../components/breakpoints";
import { useTabletSize } from "../hooks/use-media-query";

const ParallaxSection = styled.section`
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 1000px;
  /* background-color: #132330; */
  /* background: rgb(2,7,26); */
  background: linear-gradient(
    180deg,
    rgba(3, 48, 75, 1) 0%,
    rgba(26, 98, 139, 1) 18%,
    rgba(19, 35, 48, 1) 85%
  );
  background-attachment: fixed;
  background-position: -150px 0px;
  @media (max-width: ${breakpoints.desktopQHD}) {
    height: 880px;
  }
  @media (max-width: ${breakpoints.desktopHD}) {
    height: 700px;
  }
  @media (max-width: ${breakpoints.laptopL}) {
    height: 600px;
  }
  @media (max-width: ${breakpoints.laptop}) {
    height: 490px;
  }
  @media (max-width: ${breakpoints.tablet}) {
    height: 420px;
  }
  @media (max-width: ${breakpoints.mobileL}) {
    height: 330px;
  }
  @media (max-width: ${breakpoints.mobileM}) {
    height: 280px;
  }
  @media (max-width: ${breakpoints.mobileS}) {
    height: 260px;
  }
`;

const ForestGroup = styled.div`
  position: relative;
  top: 100px;

  @media (max-width: ${breakpoints.mobileL}) {
    top: 100px;
  }
  @media (max-width: ${breakpoints.mobileM}) {
    top: 80px;
  }
  @media (max-width: ${breakpoints.mobileS}) {
    top: 60px;
  }
`;

const StyledForest1 = styled(Forest1)`
  position: relative;
  top: 0px;
  z-index: 4;
  & path {
    fill: #00151e;
  }
  @media (max-width: ${breakpoints.mobileL}) {
    top: -50px;
  }
  @media (max-width: ${breakpoints.mobileM}) {
    top: -30px;
  }
`;

const StyledForest2 = styled(Forest2)`
  position: absolute;
  top: 50px;
  z-index: 0;
  & path {
    fill: #002038;
  }
`;

const StyledForest3 = styled(Forest3)`
  position: absolute;
  top: 50px;
  z-index: 0;
  & path {
    fill: #023759;
  }
`;

const StyledForest4 = styled(Forest4)`
  position: absolute;
  top: 0px;
  z-index: 0;
  & path {
    fill: #113e5a;
  }
`;

const StyledForest5 = styled(Forest5)`
  position: absolute;
  top: 0px;
  z-index: 0;
  & path {
    fill: #0c4b75;
  }
`;

const Headline = styled.span`
  display: block;
  position: relative;
  top: 90px;
  text-align: center;
  font-weight: 600;
  font-size: 64pt;
  margin: 0 0 12px 0;
  padding: 0;
  color: #ffffff;
  text-shadow: 2px 2px 14px rgba(0, 0, 0, 0.5);
  @media (max-width: ${breakpoints.desktopHD}) {
    font-size: 48pt;
    top: 70px;
  }
  @media (max-width: ${breakpoints.laptop}) {
    font-size: 36pt;
    top: 30px;
  }
  @media (max-width: ${breakpoints.mobileL}) {
    top: 0px;
  }
  @media (max-width: ${breakpoints.mobileM}) {
    padding: 0;
    margin: 0;
    font-size: 28pt;
    top: 0px;
  }
`;

const HeadlineSmall = styled.span`
  display: block;
  position: relative;
  top: 90px;
  text-align: center;
  font-size: 50pt;
  margin: 0 0 12px 0;
  padding: 0 10px;
  font-weight: 500;
  color: #e9040a;
  text-shadow: 2px 2px 14px rgba(0, 0, 0, 0.5);
  @media (max-width: ${breakpoints.desktopHD}) {
    font-size: 36pt;
    top: 70px;
  }
  @media (max-width: ${breakpoints.laptop}) {
    font-size: 28pt;
    top: 30px;
  }
  @media (max-width: ${breakpoints.mobileL}) {
    font-size: 24pt;
    top: 0px;
  }
  @media (max-width: ${breakpoints.mobileM}) {
    font-size: 20pt;
    top: 10px;
  }
`;

const Projects = styled.section`
  /* background-color: #132330; */
  background-color: #00151e;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: initial;
  z-index: 4;
  box-sizing: border-box;
  scroll-margin-top: 74px;
`;

const SliderWrapper = styled.div`
  width: 75%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  overflow: visible;
  margin: 0 auto;
  padding: 0;
  aspect-ratio: 16/7;
  margin-bottom: 30px;
  @media (max-width: ${breakpoints.desktopHD}) {
    width: 82.5%;
  }
  @media (max-width: ${breakpoints.laptop}) {
    width: 90%;
  }
  @media (max-width: ${breakpoints.tablet}) {
    width: 100%;
  }
`;

const Services = styled.section`
  /* background-color: #0e4169; */
  background: linear-gradient(30deg, rgba(102, 0, 3, 1), rgba(182, 0, 6, 1));
  position: relative;
  z-index: 4;
  padding: 30px 0px;
  padding-bottom: 0px;
  scroll-margin-top: 74px;
  ul {
    position: relative;
    list-style: square;
    font-weight: 500;
    font-size: 20pt;
    text-transform: uppercase;
    padding-right: 20px;
    @media (max-width: ${breakpoints.desktopHD}) {
      font-size: 16pt;
    }
    @media (max-width: ${breakpoints.tablet}) {
      font-size: 14pt;
    }
    @media (max-width: ${breakpoints.mobileL}) {
      font-size: 12pt;
      padding-left: 10px;
    }
    @media (max-width: ${breakpoints.mobileM}) {
      font-size: 10pt;
    }
    li {
      padding: 0;
      margin: 0px 0;
    }
    .item1 {
      margin-left: 0;
    }
    .item2 {
      margin-left: 25%;
    }
    .item3 {
      margin-left: 50%;
    }
    .item4 {
      margin-left: 75%;
    }
    .secondRow {
      margin-top: -40px;
      @media (max-width: ${breakpoints.tablet}) {
        margin-top: 10px;
      }
    }
  }
`;

const ServiceContact = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin: 80px 600px 20px 600px;
  padding: 0;
  div {
    font-size: 24pt;
    &:first-child {
      margin-right: 40px;
      @media (max-width: ${breakpoints.laptop}) {
        margin-right: 0px;
      }
    }
    @media (max-width: ${breakpoints.desktopHD}) {
      font-size: 18pt;
    }
    @media (max-width: ${breakpoints.tablet}) {
      font-size: 16pt;
    }
  }
  @media (max-width: ${breakpoints.desktopHD}) {
    margin: 80px 240px 20px 240px;
  }
  @media (max-width: ${breakpoints.laptop}) {
    justify-content: space-around;
  }
  @media (max-width: ${breakpoints.tablet}) {
    margin: 60px 100px 20px 100px;
  }
`;

const Team = styled.section`
  background: linear-gradient(rgba(24, 24, 24, 1), rgba(10, 10, 10, 1));
  top: 0px;
  /* clip-path: url('#svgPath'); */
  /* background-color: #0e4169; */
  position: relative;
  z-index: 4;
  padding: 0;
  padding-bottom: 60px;
  @media (max-width: ${breakpoints.mobileL}) {
    padding-bottom: 0;
  }
  scroll-margin-top: 74px;
  overflow: initial;
  box-sizing: border-box;
  h2 {
    margin-top: 0;
    padding: 0;
    line-height: 1;
  }
  #teamParagraph {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
  }
`;

const StyledTeamBG = styled(TeamBG)`
  position: relative;
  bottom: -5px;
`;

const TeamMember = styled.div`
  max-width: 46%;
  position: relative;
  @media (max-width: ${breakpoints.mobileL}) {
    max-width: 100%;
    margin-bottom: 60px;
  }
  h3 {
    position: absolute;
    font-size: 28pt;
    padding: 0;
    margin: 6px 14px;
    line-height: 1;
    box-sizing: border-box;
    font-weight: 400;
    z-index: 5;
    @media (max-width: ${breakpoints.desktopHD}) {
      font-size: 24pt;
    }
    @media (max-width: ${breakpoints.tablet}) {
      font-size: 18pt;
    }
  }
  ul {
    position: absolute;
    margin: 0;
    bottom: 0;
    list-style: square;
    text-transform: uppercase;
    @media (max-width: ${breakpoints.tablet}) {
      font-size: 12pt;
    }
  }
`;

const ContactSection = styled.section`
  display: flex;
  flex-direction: row;
  padding: 40px 420px;
  justify-content: center;
  background-color: #222;
  position: relative;
  scroll-margin-top: 74px;
  z-index: 4;
  @media (max-width: ${breakpoints.desktopHD}) {
    padding: 40px 160px;
  }
  @media (max-width: ${breakpoints.laptop}) {
    justify-content: space-around;
    padding: 40px 60px;
  }
  @media (max-width: ${breakpoints.tablet}) {
    padding: 40px 100px;
    flex-direction: column-reverse;
  }
  @media (max-width: ${breakpoints.mobileL}) {
    padding: 40px 40px;
  }
  @media (max-width: ${breakpoints.mobileL}) {
  }
  @media (max-width: ${breakpoints.mobileL}) {
  }
`;

const FinancingSection = styled.section`
  display: flex;
  flex-direction: row;
  padding: 0 420px 40px 420px;
  justify-content: center;
  background-color: #222;
  position: relative;
  scroll-margin-top: 74px;
  z-index: 4;
  @media (max-width: ${breakpoints.desktopHD}) {
    padding: 0 160px 40px 160px;
  }
  @media (max-width: ${breakpoints.laptop}) {
    justify-content: space-around;
    padding: 0 60px 40px 60px;
  }
  @media (max-width: ${breakpoints.tablet}) {
    padding: 0 100px 40px 100px;
    flex-direction: column-reverse;
  }
  @media (max-width: ${breakpoints.mobileL}) {
    padding: 0 40px 40px 40px;
  }
  @media (max-width: ${breakpoints.mobileL}) {
  }
  @media (max-width: ${breakpoints.mobileL}) {
  }
`;

const FinancingImageWrapper = styled.div`
  width: 280px;

  @media (max-width: 768px) {
    /* Adjust this breakpoint as needed */
    width: 200px; /* New width for smaller screens */
  }

  img {
    /* Ensures the image itself respects the container's width */
    width: 100%;
    height: auto;
  }
`;

const Email = styled.div`
  position: relative;
  font-size: 14pt;
  white-space: nowrap;
  padding: 0 4px;
`;

const Phone = styled.div`
  position: relative;
  font-size: 14pt;
  margin-bottom: 20px;
  white-space: nowrap;
  padding: 0 4px;
`;

const Adresse = styled.span`
  line-height: 2;
  position: relative;
  margin-right: 48px;
  margin-bottom: 40px;
  font-size: 14pt;
  span {
    white-space: nowrap;
  }
`;

const Contact = styled.div`
  position: relative;
  box-sizing: border-box;
`;

const ContactOptions = styled.div`
  display: block;
  box-sizing: border-box;
  @media (max-width: ${breakpoints.mobileL}) {
    margin-top: 40px;
  }
`;

const ContactContent = styled.div`
  display: flex;
  color: #fff;
  a {
    color: #fff;
  }
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
`;

const Social = styled.div`
  position: relative;
`;

const SocialLinks = styled.div`
  position: relative;
  display: flex;
  justify-content: start;
  margin-right: 300px;
  @media (max-width: ${breakpoints.desktopQHD}) {
    margin-right: 200px;
  }
  @media (max-width: ${breakpoints.desktopHD}) {
    margin-right: 60px;
  }
  @media (max-width: ${breakpoints.laptop}) {
    margin-right: 16px;
  }
  a {
    color: #e9040a;
    padding: 0;
    margin-right: 16px;
  }
`;

const Financing = styled.div`
  position: relative;
  display: block;
  justify-content: start;
  a {
    color: #e9040a;
    padding: 0;
    margin-right: 16px;
  }
`;

const Paragraph = styled.p`
  line-height: 2;
  font-size: 18pt;
  position: relative;
  z-index: 4;
  color: #ffffff;
  margin: 20px auto;
  padding: 0;
  max-width: 1024px;
  @media (max-width: ${breakpoints.desktopHD}) {
    font-size: 16pt;
    max-width: 800px;
  }
  @media (max-width: ${breakpoints.laptopL}) {
    margin: 20px auto;
  }
  @media (max-width: ${breakpoints.laptop}) {
    font-size: 13pt;
    margin: 20px 140px;
    max-width: 100%;
  }
  @media (max-width: ${breakpoints.tablet}) {
    margin: 20px 60px;
  }
  @media (max-width: ${breakpoints.mobileL}) {
    margin: 20px 30px;
    font-size: 11pt;
  }
`;

const SubHeadline = styled.h2`
  padding: 0;
  display: block;
  position: relative;
  font-size: 48pt;
  font-weight: 500;
  color: #ffffff;
  margin: 60px auto;
  max-width: 1024px;
  @media (max-width: ${breakpoints.desktopHD}) {
    font-size: 42pt;
    max-width: 800px;
  }
  @media (max-width: ${breakpoints.laptopL}) {
    margin: 40px auto;
    font-size: 36pt;
  }
  @media (max-width: ${breakpoints.laptop}) {
    margin: 34px 140px;
  }
  @media (max-width: ${breakpoints.tablet}) {
    font-size: 32pt;
    /* font-size: 28pt; */
    margin: 28px 60px;
    font-size: 32pt;
  }
  @media (max-width: ${breakpoints.mobileL}) {
    margin: 20px 30px;
    font-size: 28pt;
  }
  @media (max-width: ${breakpoints.mobileM}) {
    font-size: 24pt;
  }
`;

const SubHeadlineSmall = styled.h3`
  display: block;
  font-size: 30pt;
  font-weight: 500;
  margin: 20px 0 32px 0;
  padding: 0;
  color: #fff;
  @media (max-width: ${breakpoints.tablet}) {
    /* font-size: 24pt; */
    top: 70px;
  }
  @media (max-width: ${breakpoints.mobileM}) {
    font-size: 24pt;
  }
`;

const ServicesList = styled(motion.ul)`
  margin: 40px 0;
`;

const IndexPage = () => {
  const { t } = useTranslation();

  const { scrollY } = useScroll();

  const isTabletSize = useTabletSize();

  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const container = {
    hidden: {},
    show: {
      transition: {
        delayChildren: 0.5,
        staggerChildren: 0.25,
        staggerDirection: 1,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, scale: 0 },
    show: { opacity: 1, scale: [1, 1.2, 1] },
  };

  const yRange1 = useTransform(
    scrollY,
    isTabletSize ? [0, 400] : [0, 200],
    isTabletSize ? [0, 0] : [0, 0]
  );
  const yRange2 = useTransform(
    scrollY,
    isTabletSize ? [0, 800] : [0, 200],
    isTabletSize ? [0, 500] : [0, 110]
  );
  const yRange3 = useTransform(
    scrollY,
    isTabletSize ? [0, 1000] : [0, 300],
    isTabletSize ? [0, 1060] : [0, 280]
  );
  const yRange4 = useTransform(
    scrollY,
    isTabletSize ? [0, 800] : [0, 200],
    isTabletSize ? [0, 980] : [0, 230]
  );
  const yRange5 = useTransform(
    scrollY,
    isTabletSize ? [0, 800] : [0, 300],
    isTabletSize ? [0, 1040] : [0, 360]
  );
  const yRange6 = useTransform(
    scrollY,
    isTabletSize ? [0, 200] : [0, 200],
    isTabletSize ? [0, 0] : [0, 0]
  );

  const y1 = yRange1;
  const y2 = yRange2;
  const y3 = yRange3;
  const y4 = yRange4;
  const y5 = yRange5;
  const y6 = yRange6;

  return (
    <Layout isIndex="true">
      <ParallaxSection>
        <ForestGroup>
          <motion.div style={{ y: y5 }}>
            <StyledForest5 />
          </motion.div>
          <motion.div style={{ y: y4 }}>
            <StyledForest4 />
          </motion.div>
          <motion.div style={{ y: y3 }}>
            <StyledForest3 />
          </motion.div>
          <motion.div style={{ y: y2 }}>
            <StyledForest2 />
          </motion.div>
          <motion.div style={{ y: y3 }}>
            <h1>
              <Headline>Foxel Media</Headline>
              <HeadlineSmall>
                <Trans>Index-Page.Filmproduktion aus Thüringen</Trans>
              </HeadlineSmall>
            </h1>
          </motion.div>
          <motion.div style={{ y: y1 }}>
            <StyledForest1 />
          </motion.div>
        </ForestGroup>
      </ParallaxSection>
      <motion.div style={{ y: y6 }}>
        <Projects id="work">
          <SubHeadline>
            <Trans>Aktuelle Projekte</Trans>
          </SubHeadline>
          <SliderWrapper>
            <Slider />
          </SliderWrapper>
        </Projects>
      </motion.div>
      <Services id="services">
        <SubHeadline>
          <Trans>Leistungen</Trans>
        </SubHeadline>
        <Paragraph>
          <Trans>Index-Page.Leistungen1</Trans>
        </Paragraph>
        <Paragraph>
          <ServicesList
            variants={container}
            initial="hidden"
            animate={isInView ? "show" : "hidden"}
            ref={ref}
          >
            <motion.li variants={item} class="item1">
              <Trans>Index-Page.Werbefilme</Trans>
            </motion.li>
            <motion.li variants={item} class="item2">
              <Trans>Index-Page.Produktvideos</Trans>
            </motion.li>
            <motion.li variants={item} class="item3">
              <Trans>Index-Page.Dokumentationen</Trans>
            </motion.li>
            <motion.li variants={item} class="item4">
              <Trans>Index-Page.Erklärvideos</Trans>
            </motion.li>
            <motion.li variants={item} class="item1 secondRow">
              <Trans>Index-Page.Recruitingvideos</Trans>
            </motion.li>
            <motion.li variants={item} class="item2">
              <Trans>Index-Page.Imagefilme</Trans>
            </motion.li>
            <motion.li variants={item} class="item3">
              <Trans>Index-Page.Social Media Spots</Trans>
            </motion.li>
            <motion.li variants={item} class="item4">
              <Trans>Index-Page.Animationen</Trans>
            </motion.li>
          </ServicesList>
        </Paragraph>
        <Paragraph>
          <Trans>Index-Page.Leistungen2</Trans>
        </Paragraph>
        <ServiceContact>
          <IconContext.Provider
            value={{
              color: "#000",
              size: "1.3em",
              style: { verticalAlign: "middle" },
            }}
          >
            <Phone>
              <MdOutlinePhoneIphone />
              <Obfuscate tel={t("Telefonnummer")} />
            </Phone>
            <Email>
              <MdMailOutline />
              <Obfuscate email="info@foxel.media" />
            </Email>
          </IconContext.Provider>
        </ServiceContact>
        <StyledTeamBG />
      </Services>
      <Team id="team">
        <SubHeadline>
          <Trans>Team</Trans>
        </SubHeadline>
        <Paragraph id="teamParagraph">
          <TeamMember>
            <h3>Clarissa Gabriel</h3>
            <StaticImage
              src="../../images/clarissa_1080.jpg"
              alt="Clarissa Gabriel"
              placeholder="blurred"
              quality={100}
            />

            <ul>
              <li>
                <Trans>Index-Page.Konzeption</Trans>
              </li>
              <li>Marketing & PR</li>
              <li>
                <Trans>Index-Page.Set-Ton</Trans>
              </li>
              <li>Editing</li>
            </ul>
          </TeamMember>
          <TeamMember>
            <h3>Denny Nguyen</h3>
            <StaticImage
              src="../../images/denny_1080.jpg"
              alt="Denny Nguyen"
              placeholder="blurred"
              quality={100}
            />
            <ul>
              <li>
                <Trans>Index-Page.Regie</Trans>
              </li>
              <li>
                <Trans>Index-Page.Kamera</Trans>
              </li>
              <li>Color Grading</li>
              <li>3D-Animation</li>
            </ul>
          </TeamMember>
        </Paragraph>
      </Team>
      <ContactSection id="contact">
        <Social>
          <SubHeadlineSmall>Social</SubHeadlineSmall>
          <SocialLinks>
            <IconContext.Provider
              value={{ size: "2em", style: { verticalAlign: "middle" } }}
            >
              <motion.a
                href="https://www.youtube.com/channel/UCxwkzA-YY2Y3JRFzbLhWwhg"
                target="_blank"
                rel="noreferrer"
                aria-label={t("Index-Page.YouTube-Label")}
                whileHover={{ scale: 1.2 }}
              >
                <ImYoutube />
              </motion.a>
              <motion.a
                href="https://vimeo.com/foxelmedia"
                target="_blank"
                rel="noreferrer"
                aria-label={t("Index-Page.Vimeo-Label")}
                whileHover={{ scale: 1.2 }}
              >
                <ImVimeo2 />
              </motion.a>
              <motion.a
                href="https://www.instagram.com/foxelmedia"
                target="_blank"
                rel="noreferrer"
                aria-label={t("Index-Page.Instagram-Label")}
                whileHover={{ scale: 1.2 }}
              >
                <ImInstagram />
              </motion.a>
            </IconContext.Provider>
          </SocialLinks>
        </Social>
        <Contact>
          <SubHeadlineSmall>
            <Trans>Kontakt</Trans>
          </SubHeadlineSmall>
          <ContactContent>
            <Adresse>
              <span>Foxel Media GmbH</span>
              <br />
              <span>Oehrenstöcker Chaussee 21</span>
              <br />
              <span> 98693 Ilmenau</span>
            </Adresse>
            <ContactOptions>
              <IconContext.Provider
                value={{
                  color: "#e9040a",
                  size: "1.3em",
                  style: { verticalAlign: "middle" },
                }}
              >
                <Phone>
                  <MdOutlinePhoneIphone />
                  <Obfuscate tel={t("Telefonnummer")} />
                </Phone>
                <Email>
                  <MdMailOutline />
                  <Obfuscate email="info@foxel.media" />
                </Email>
              </IconContext.Provider>
            </ContactOptions>
          </ContactContent>
        </Contact>
      </ContactSection>
      <FinancingSection>
        <Financing>
          <FinancingImageWrapper>
            <Link to="/innoinvest">
              {" "}
              <StaticImage
                src="../../images/eu_emblem.png"
                alt="EU Förderung"
                placeholder="blurred"
                quality={100}
              />
            </Link>
          </FinancingImageWrapper>
        </Financing>
      </FinancingSection>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    site {
      siteMetadata {
        title
        author
        defaultCharset: charset
        description
        siteUrl
      }
    }
  }
`;

// FIX FOR i18next Plugin Support for Gatsbys Head API
export const Head = ({ data }) => {
  const locales = data.locales.edges[0].node.data;
  let obj = undefined;
  if (locales) {
    obj = JSON.parse(locales);
  }
  return (
    <SeoComponent
      title={`${data.site.siteMetadata.title} | ${obj?.["Index-Page"].Title}`}
      description={obj?.["Index-Page"].Description}
    />
  );
};
