import * as React from "react";
import { useState, useRef, useEffect } from "react";
import { motion, useMotionValue } from "framer-motion";
import styled from "styled-components";
// import { Trans } from 'gatsby-plugin-react-i18next';
import { useStaticQuery, graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useI18next } from 'gatsby-plugin-react-i18next';
import breakpoints from "./breakpoints";

const Carousel = styled.div`
  position: relative;
  height: 100%;
  aspect-ratio: 16/9;
  margin: 0 auto;
  box-sizing: border-box;
  `

const Next = styled(motion.div)`
  opacity: 0.5;
  top: calc(50% - 80px);
  position: absolute;
  right: -40px;
  display: flex;
  justify-content: center;
  color: white;
  width: 0;
  cursor: pointer;
  height: 0;
  border-style: solid;
  border-width: 80px 0 80px 24px;
  border-color: transparent transparent transparent #e9040a;
  border-left-color: #e9040a;
  z-index: 6;
  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`

const Prev = styled(motion.div)`
  opacity: 0.5;
  top: calc(50% - 80px);
  position: absolute;
  left: -40px;
  display: flex;
  justify-content: center;
  width: 0;
  cursor: pointer;
  height: 0;
  border-style: solid;
  border-width: 80px 24px 80px 0;
  border-color: transparent #e9040a transparent transparent;
  z-index: 6;
  @media (max-width: ${breakpoints.tablet}) {
    display: none;
  }
`

const Dots = styled.div`
  pointer-events: none;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  position: relative;
  bottom: 14px;
`

const Dot = styled(motion.span)`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: #fff;
  display: inline-block;
  margin: 0 4px;
  z-index: 6;
  @media (max-width: ${breakpoints.desktopHD}) {
    width: 10px;
  height: 10px;
  }
`

const SliderWrapper = styled.div`
  position: relative;
  height: 100%;
`

const SliderContent = styled(motion.div)` 
  position: absolute;
  /* padding: 40px;
  background-color: red; */
  width: 100%;
  overflow: hidden;
  top: 0;
  box-sizing: border-box;
  .disabled {
    pointer-events: none;
  }
  img {
    height: 100%;
    width: 100%;
  }
`

const ProjectInfo = styled.div`
  background: linear-gradient(0deg, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.7) 50%, rgba(0,0,0,0) 100%);
  padding: 40px 20px 20px 20px;
  position: absolute;
  bottom: 0;
  width: 100%;
  color: #fff;
  overflow: hidden;
  box-sizing: border-box;
  `

const ProjectCategory = styled.span`
  display: inline-block;
  text-transform: uppercase;
  font-weight: 600;
  margin-right: 10px;
  margin-bottom: 4px;
  font-size: 36pt;
  @media (max-width: ${breakpoints.desktopHD}) {
    font-size: 28pt;
  }
  @media (max-width: ${breakpoints.laptop}) {
    font-size: 20pt;
  }
  @media (max-width: ${breakpoints.mobileL}) {
    font-size: 15pt;
  }
  @media (max-width: ${breakpoints.mobileM}) {
    font-size: 12pt;
  }
`
const ProjectClient = styled.span`
  display: inline-block;
  font-size: 36pt;
  margin-bottom: 4px;
  @media (max-width: ${breakpoints.desktopHD}) {
    font-size: 28pt;
  }
  @media (max-width: ${breakpoints.laptop}) {
    font-size: 20pt;
  }
  @media (max-width: ${breakpoints.mobileL}) {
    font-size: 15pt;
  }
  @media (max-width: ${breakpoints.mobileM}) {
    font-size: 12pt;
  }
`

const ProjectTitle = styled.span`
  display: flex;
  flex-wrap: wrap;
  font-size: 28pt;
  @media (max-width: ${breakpoints.desktopHD}) {
    font-size: 24pt;
  }
  @media (max-width: ${breakpoints.laptop}) {
    font-size: 16pt;
  }
  @media (max-width: ${breakpoints.mobileL}) {
    font-size: 12pt;
  }
  @media (max-width: ${breakpoints.mobileM}) {
    font-size: 9pt;
  }
`

export const Slider = () => {

  const { language } = useI18next();

  const queryData = useStaticQuery(graphql`
  query {
    allMdx(filter: {frontmatter: {highlighted: {eq: true}}}) {
      nodes {
        frontmatter {
          shortTitle
          title
          category
          client
          highlighted
          locale
          featuredImage{
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
                breakpoints: [480, 750, 1024, 1366, 1920]
              )
            }
          }
          url
        }
      }
    }
  }
  `)

  const data = queryData.allMdx.nodes.filter((node) => node.frontmatter.locale.includes(language))

  const [position, positionSet] = useState(0);
  const [restartTimer, restartTimerSet] = useState(false);

  // ----------------------------------------

  const intervalRef = useRef();

  const x = useMotionValue(0);
  const containerRef = useRef();
  const isDragging = useRef(false);

  const handleEndDrag = (e, drag) => {
    setTimeout(() => {
      isDragging.current = false;
    }, 150);
    const clientWidth = containerRef.current?.clientWidth || 0;

    if (drag.offset.x > clientWidth / 4 && position > 0) {
      positionSet(position - 1);
    } else if (drag.offset.x < -clientWidth / 4 && position < data.length - 1) {
      positionSet(position + 1);
    }
    // triggers a re-render to restart the timer
    else {
      restartTimerSet(!restartTimer);
    }
  }

  useEffect(() => {
    const id = setInterval(() => {
      if (position < data.length - 1) {
        positionSet(position + 1);
      }
      else if (position === (data.length - 1)) {
        positionSet(0);
      }
    }, 4000);
    intervalRef.current = id;
    return () => {
      clearInterval(intervalRef.current);
    };
  });

  const onRight = () => {
    clearInterval(intervalRef.current);
    if (position < data.length - 1) {
      positionSet(position + 1);
    }
    else if (position === data.length - 1) {
      positionSet(0);
    }
  };

  const onLeft = () => {
    clearInterval(intervalRef.current);
    if (position > 0) {
      positionSet(position - 1);
    }
    else if (position === 0) {
      positionSet(data.length - 1);
    }
  };

  return (
    <Carousel>
      <div>
        <Prev onClick={onLeft} whileHover={{ opacity: 1.0, scale: 1.1 }} />
        <Next onClick={onRight} whileHover={{ opacity: 1.0, scale: 1.1 }} />
      </div>
      <SliderWrapper>
        {data.map((node, index) => (
          <SliderContent ref={containerRef} drag="x" whileDrag={{ scale: 0.85 }} onDragStart={() => {
            isDragging.current = true;
            clearInterval(intervalRef.current);
          }} onDragEnd={handleEndDrag} dragSnapToOrigin={true} dragElastic={0.1} key={index}
            animate={{
              rotate: 0, left: `${(index - position) * 100}%`, scale: index === position ? 1 : 0.85, opacity: index === position ? 1 : 0.5,
            }}
            transition={{ duration: 2.0, type: "spring", stiffness: 160, damping: 50, }}
            style={{ x, }}>
            <Link draggable={false} onClick={(e) => {
              if (isDragging.current === true) {
                e.preventDefault();
              }
            }} class={index === position ? '' : 'disabled'} to={`/projects/${node.frontmatter.url}`} style={{ padding: 0, color: 'transparent' }}>
              <GatsbyImage draggable={false} image={getImage(node.frontmatter.featuredImage)} alt={node.frontmatter.title} />
              <ProjectInfo>
                <ProjectCategory>{node.frontmatter.category}:</ProjectCategory>
                <ProjectClient>{node.frontmatter.client}</ProjectClient>
                <ProjectTitle>{node.frontmatter.shortTitle}</ProjectTitle>
              </ProjectInfo>
            </Link>
          </SliderContent>
        ))}
      </SliderWrapper>
      <Dots>
        {data.map((node, index) => (
          <Dot
            key={node.frontmatter.url}
            initial={{ scale: 0 }}
            animate={{
              scale: index === position ? 1 : 0.5,
              opacity: index === position ? 1 : 0.2,
            }}
            transition={{
              type: "spring",
              stiffness: 160,
              damping: 50,
            }} />))}
      </Dots>
    </Carousel>

  );
}

export default Slider;